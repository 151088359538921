import React, { FC, InputHTMLAttributes } from "react";

interface FloatingLabelInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  name: string
}

const FloatingLabelInput: FC<FloatingLabelInputProps> = ({ label, type = "text", value, onChange, name, onBlur }) => {
  return (
    <div className="relative w-full bg-transparent">
      <input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder=" "
        className={`peer w-full border border-gray-300 rounded-md px-3 pt-5 pb-2 text-base text-gray-900 focus:outline-none focus:ring-0 focus:border-blue-500 bg-transparent`}
      />
      <label
        className={`absolute left-3 top-1 text-gray-500 text-xs transform transition-all duration-200 
          peer-placeholder-shown:top-5 peer-placeholder-shown:text-xs peer-placeholder-shown:text-gray-400 
          peer-focus:top-1 peer-focus:text-sm peer-focus:text-blue-500`}
      >
        {label}
      </label>
    </div>
  );
};

export default FloatingLabelInput;
