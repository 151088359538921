import { createContext, ReactNode, useCallback, useState, useEffect } from "react";

type ErrorContextType = {
  error: Record<string, unknown> | null;
  errorBool: boolean;
  setError: (error: Record<string, unknown>) => void;
  clearError: () => void;
};

export const ErrorContext = createContext<ErrorContextType>({
  error: null,
  errorBool: false,
  setError: () => { },
  clearError: () => { },
});

const ErrorProvider = ({ children }: { children: ReactNode }) => {
  const [error, setErrorState] = useState<Record<string, unknown>>({});
  const [errorBool, setErrorBool] = useState<boolean>(false);

  const setError = useCallback((error: Record<string, unknown>) => {
    setErrorState(error);
  }, []);

  const clearError = useCallback(() => {
    setErrorState({});
  }, []);

  useEffect(() => {
    setErrorBool(Object.keys(error).length > 0);
  }, [error]);

  return (
    <ErrorContext.Provider value={{ error, errorBool, setError, clearError }}>
      {children}
    </ErrorContext.Provider>
  );
};

export default ErrorProvider;