import { createContext, ReactNode, useCallback, useState } from "react";

type PageContextType = {
  page: string;
  sub: string
  changePage: (page?: string) => void;
  changeSub: (sub: string) => void;
}

export const PageContext = createContext<PageContextType>({
  page: "",
  sub: "",
  changePage: () => { },
  changeSub: () => { },
});

const PageProvider = ({ children }: { children: ReactNode }) => {
  const [page, setPage] = useState<string>("");
  const [sub, setSub] = useState<string>("Card");


  const changePage = useCallback(
    (page?: string) => {
      if (page) {
        setPage(page);
      } else {
        setPage("");
      }
    },
    [setPage]
  );

  const changeSub = useCallback(
    (sub: string) => {
      if (sub) {
        setSub(sub);
      }
    },
    [setSub]
  );

  return (
    <PageContext.Provider value={{ page, sub, changePage, changeSub }}>
      {children}
    </PageContext.Provider>
  );
};

export default PageProvider;