interface ValidationResult {
  isValid: boolean;
  errors: Record<string, string>;
}

const isExpiryDateValid = (expirationDate: string): boolean => {
  const [month, year] = expirationDate.split('/').map(Number);
  if (!month || !year) return false;

  const now = new Date();
  const expiry = new Date(2000 + year, month - 1);

  return expiry >= now;
};

export const validateCardForm = (data: {
  cardNumber: string;
  expirationDate: string;
  cvv: string;
}): ValidationResult => {
  const errors: Record<string, string> = {};

  if (!data.cardNumber) {
    errors.cardNumber = "Card number is required";
  }

  if (!data.expirationDate) {
    errors.expirationDate = "Expiration date is required";
  } else if (!/^(0[1-9]|1[0-2])\/\d{2}$/.test(data.expirationDate)) {
    errors.expirationDate = "Expiration date must be in MM/YY format";
  } else if (!isExpiryDateValid(data.expirationDate)) {
    errors.expirationDate = "Expiration date cannot be in the past";
  }

  if (!data.cvv) {
    errors.cvv = "CVV is required";
  } else if (!/^\d{3,4}$/.test(data.cvv)) {
    errors.cvv = "CVV must be 3 or 4 digits";
  }

  return {
    isValid: Object.keys(errors).length === 0,
    errors,
  };
};

export const validatePin = (data: { pin: string }): ValidationResult => {
  const errors: Record<string, string> = {};

  if (!data.pin) {
    errors.pin = "PIN is required";
  } else if (!/^\d{4}$/.test(data.pin)) {
    errors.pin = "PIN must be 4 digits";
  }

  return {
    isValid: Object.keys(errors).length === 0,
    errors,
  };
};

export const validateOtp = (data: { otp: string }): ValidationResult => {
  const errors: Record<string, string> = {};

  if (!data.otp) {
    errors.otp = "OTP is required";
  } else if (!/^\d{6}$/.test(data.otp)) {
    errors.otp = "OTP must be 6 digits";
  }

  return {
    isValid: Object.keys(errors).length === 0,
    errors,
  };
};

export const validateBirthday = (data: { birthday: string }): ValidationResult => {
  const errors: Record<string, string> = {};

  if (!data.birthday) {
    errors.birthday = "Birthday is required";
  } else if (!/^\d{2}\/\d{2}\/\d{4}$/.test(data.birthday)) {
    errors.birthday = "Birthday must be in DD/MM/YYYY format";
  }

  return {
    isValid: Object.keys(errors).length === 0,
    errors,
  };
};

export const validatePhone = (data: { phone: string }): ValidationResult => {
  const errors: Record<string, string> = {};

  if (!data.phone) {
    errors.phone = "Phone number is required";
  } else if (!/^\d{10,15}$/.test(data.phone)) {
    errors.phone = "Phone number must be between 10 and 15 digits";
  }

  return {
    isValid: Object.keys(errors).length === 0,
    errors,
  };
};

interface AddressForm {
  address: string;
  city: string;
  state: string;
  zipCode: string;
}

export const validateAddress = (form: AddressForm) => {
  const errors: Record<string, string> = {};

  if (!form.address.trim()) {
    errors.address = "Address is required";
  }

  if (!form.city.trim()) {
    errors.city = "City is required";
  }

  if (!form.state.trim()) {
    errors.state = "State is required";
  }

  if (!form.zipCode.trim()) {
    errors.zipCode = "Zip code is required";
  } else if (!/^\d{5}(-\d{4})?$/.test(form.zipCode)) {
    errors.zipCode = "Zip code is invalid";
  }

  return {
    isValid: Object.keys(errors).length === 0,
    errors,
  };
};
