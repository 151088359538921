import Button from 'components/ui/Button/Button'
import { SwitcherProps } from 'components/PageSwitcher'
import Payment from 'components/screens/Payment'
import { usePageContext } from 'context/hooks/usePageContext'
import { useTransferContext } from 'context/hooks/useTransferContext'
import useGetTransferStatus from 'hooks/useGetTransferStatus'
import { FC, useEffect, useState } from 'react'
import { formatCurrency } from 'utils'
import Timer from 'components/ui/TImer/Timer'
import Icon from 'components/ui/Icon/Icon'
import ErrorComponent from 'components/screens/Error'

import { ReactComponent as Spinner } from '../../assets/icons/spinner.svg'
import Timeout from 'components/screens/Timeout'
import { useTransactionContext } from 'context/hooks/useTransactionContext'

const PayWithTransfer: FC<SwitcherProps> = ({ amount }) => {
  const [copy, setCopy] = useState<boolean>(false)
  const { sub, changeSub } = usePageContext();
  const { ceviantTransactionId } = useTransactionContext();
  const { virtualAccountName, bankCode, virtualAccountNumber, validityTime } = useTransferContext();
  const { status, triggerFetch } = useGetTransferStatus(ceviantTransactionId.slice(8));

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(virtualAccountNumber);
      setCopy(true);
      console.log("Text copied to clipboard");
    } catch (error) {
      console.error("Failed to copy text to clipboard:", error);
      alert("Unable to copy text. Clipboard access is blocked.");
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => setCopy(false), 2000);
    return () => clearTimeout(timer);
  }, [copy]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (status === null || status === "INITIALISED") {
        triggerFetch();
      } else {
        clearInterval(interval);
      }
    }, 15000);

    return () => clearInterval(interval);
  }, [status, triggerFetch]);

  return (
    <>
      <div className='space-y-3 max-w-[340px] m-auto font-primary'>
        {
          sub === "Loading" && (
            <div className='flex flex-col items-center justify-center h-full'>
              <Icon svg={Spinner} size={70} />
              <p>Loading</p>
            </div>
          )
        }
        {
          sub === "Transfer" && (
            <>
              <h3 className='font-semibold text:lg md:text-xl text-center'>
                Pay <span className='text-[#0284F8]'>{formatCurrency(amount, 'NGN')}</span> to the account details below:
              </h3>
              <div className='space-y-6'>
                <div className='space-y-4'>
                  <div className='flex items-center justify-between border-b-2 pb-1 pt-3'>
                    <p className='text-[#121212]/60 text-sm md:text-base font-semibold'>Amount:</p>
                    <p className='text-[#121212] text-sm md:text-base font-semibold'>{formatCurrency(amount, 'NGN')}</p>
                  </div>
                  <div className='flex items-center justify-between border-b-2 pb-1 pt-3'>
                    <p className='text-[#121212]/60 text-sm md:text-base font-semibold'>Account Name:</p>
                    <p className='text-[#121212] text-sm md:text-base font-semibold'>{virtualAccountName}</p>
                  </div>
                  <div className='flex items-center justify-between border-b-2 pb-1 pt-3'>
                    <p className='text-[#121212]/60 text-sm md:text-base font-semibold'>Bank Name:</p>
                    <p className='text-[#121212] text-sm md:text-base font-semibold'>{bankCode} MFB</p>
                  </div>
                  <div className='flex items-center justify-between border-b-2 pb-1 pt-3'>
                    <p className='text-[#121212]/60 text-sm md:text-base font-semibold'>Account Number:</p>
                    <p className='text-[#121212] text-sm md:text-base font-semibold flex items-center gap-2'>
                      {virtualAccountNumber}
                      {
                        copy ? (
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                            <path fill="#0284F8" d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992zm-.92 5.14l.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486z" />
                          </svg>
                        ) : (
                          <img
                            src="/icons/copy.svg"
                            alt="copy"
                            className='cursor-pointer'
                            onClick={() => handleCopy()}
                          />
                        )
                      }
                    </p>
                  </div>
                  <Timer duration={validityTime * 60} />
                </div>
                <div className='w-full flex gap-4'>
                  <Button type="submit" variant='primary' onClick={() => changeSub("Paying")}>I have paid</Button>
                  <Button variant='outline' onClick={() =>
                    window.parent.postMessage({ message: 'closeCeviantSDK' }, '*')
                  }>Cancel</Button>
                </div>
              </div>
            </>
          )
        }
        {sub === "Paying" && (<Payment />)}
        {sub === "Error" && (<ErrorComponent />)}
        {sub === "Timeout" && (<Timeout />)}
      </div>
    </>
  )
}

export default PayWithTransfer