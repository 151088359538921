import { v4 as uuidv4 } from 'uuid';

export const formatCurrency = (
  amount: number | string | undefined | null,
  currency: string
): string => {
  if (!amount) return 'N/A';
  if (typeof amount === 'string') {
    amount = parseFloat(amount);
  }
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
  }).format(amount);
};

export const getUniqueKey = () => {
  return uuidv4();
};

export const debounce = (func: Function, delay: number) => {
  let timeoutId: NodeJS.Timeout;
  return (...args: any[]) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export const checkResponse = (
  response: string,
  updateSub: (sub: string) => void,
  updatePage: (sub: string) => void) => {
  switch (response) {
    case 'SEND_PIN':
      updateSub("PinAuth")
      return
    case 'SEND_OTP':
      updateSub("OtpAuth")
      return
    case 'SEND_BIRTHDAY':
      updateSub("BirthdayAuth")
      return
    case 'SEND_PHONE':
      updateSub("PhoneAuth")
      return
    case 'SEND_ADDRESS':
      updateSub("AddressAuth")
      return
    case 'APPROVED':
      updatePage("Authenticating")
      updateSub("Success")
      return
    case 'FAILED':
      console.log("FAILED")
      return
    case 'TIMEOUT':
      console.log("Timeout")
      return
    case 'OPEN_URL':
      console.log("OPEN URL")
      return
    default:
      console.log("Unknown action!!")
      return
  }
}

export const getTransferStatus = (
  status: string,
  updateSub: (sub: string) => void,
  updatePage: (sub: string) => void
) => {
  switch (status) {
    case 'APPROVED':
      updatePage("Authenticating");
      updateSub("Success");
      return;
    case 'DECLINED':
      updateSub("Declined");
      return;
    case 'FAILED':
      updateSub("Failed");
      return;
    case 'IN_PROGRESS':
      updateSub("Paying");
      return;
    case 'CANCELLED':
      updateSub("Cancelled");
      return;
    case 'INITIALISED':
      return;
    case 'SETTLED':
      updatePage("Authenticating");
      updateSub("Success");
      return;
    case 'PENDING':
      updateSub("Paying");
      return;
    case 'TIMED_OUT':
      updateSub("Timeout");
      return;
    case 'UNKNOWN':
      updateSub("Error");
      return;
    case 'REFUNDED':
      updateSub("Refunded");
      return;
    case 'REFUND_IN_PROGRESS':
      updateSub("RefundInProgress");
      return;
    default:
      console.log("Unknown status!!");
      return;
  }
};
