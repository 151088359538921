import { createContext, ReactNode, useCallback, useState } from "react";

type TransactionContextType = {
  ceviantTransactionId: string;
  merchantLogo: string | null;
  merchantName: string;
  providerPublicKey: string;
  merchantCollectionPublicKey: string;
  subAccountId: string;
  changeTransactionDetails: (details: Partial<TransactionContextType>) => void;
};

export const TransactionContext = createContext<TransactionContextType>({
  ceviantTransactionId: "",
  merchantLogo: null,
  merchantName: "",
  providerPublicKey: "",
  merchantCollectionPublicKey: "",
  subAccountId: "",
  changeTransactionDetails: () => { },
});

const TransactionProvider = ({ children }: { children: ReactNode }) => {
  const [transactionDetails, setTransactionDetails] = useState<TransactionContextType>({
    ceviantTransactionId: "",
    merchantLogo: null,
    merchantName: "",
    providerPublicKey: "",
    merchantCollectionPublicKey: "",
    subAccountId: "",
    changeTransactionDetails: () => { },
  });

  const changeTransactionDetails = useCallback(
    (details: Partial<TransactionContextType>) => {
      setTransactionDetails((prevDetails) => ({
        ...prevDetails,
        ...details,
      }));
    },
    [setTransactionDetails]
  );

  return (
    <TransactionContext.Provider value={{ ...transactionDetails, changeTransactionDetails }}>
      {children}
    </TransactionContext.Provider>
  );
};

export default TransactionProvider;