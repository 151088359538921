import { createContext, ReactNode, useCallback, useState } from "react";

type CardContextType = {
  cardNumber: string;
  expirationDate: string;
  cvv: string;
  pin: string;
  changeCardDetails: (details: Partial<CardContextType>) => void;
};

export const CardContext = createContext<CardContextType>({
  cardNumber: "",
  expirationDate: "",
  cvv: "",
  pin: "",
  changeCardDetails: () => {},
});

const CardProvider = ({ children }: { children: ReactNode }) => {
  const [cardDetails, setCardDetails] = useState<CardContextType>({
    cardNumber: "",
    expirationDate: "",
    cvv: "",
    pin: "",
    changeCardDetails: () => {},
  });

  const changeCardDetails = useCallback(
    (details: Partial<CardContextType>) => {
      setCardDetails((prevDetails) => ({
        ...prevDetails,
        ...details,
      }));
    },
    [setCardDetails]
  );

  return (
    <CardContext.Provider value={{ ...cardDetails, changeCardDetails }}>
      {children}
    </CardContext.Provider>
  );
};

export default CardProvider;
