import React, { useEffect, useState } from 'react';

interface TimerProps {
  duration: number;
}

const Timer: React.FC<TimerProps> = ({ duration }) => {
  const [timeLeft, setTimeLeft] = useState<number>(duration);
  const [minutes, setMinutes] = useState<string>('00');
  const [seconds, setSeconds] = useState<string>('00');

  useEffect(() => {
    const interval = setInterval(() => {
      const mins = Math.floor(timeLeft / 60);
      const secs = timeLeft % 60;

      setMinutes(mins < 10 ? `0${mins}` : `${mins}`);
      setSeconds(secs < 10 ? `0${secs}` : `${secs}`);

      setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    return () => clearInterval(interval);
  }, [timeLeft, duration]);

  return (
    <div>
      <p className='text-center text-sm font-medium text-[#9E9E9E]'>Expires in <span className='text-[#0284F8]'>{minutes}:{seconds}</span></p>
    </div>
  );
};

export default Timer;