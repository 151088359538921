import { useTransactionContext } from 'context/hooks/useTransactionContext';
import { useEffect } from 'react'

const Success = ({ amount }: { amount: number }) => {
  const { ceviantTransactionId, merchantName } = useTransactionContext()

  useEffect(() => {
    const timer = setTimeout(() => window.parent.postMessage({
      message: 'ceviantPaymentSuccess',
      payload: ceviantTransactionId
    }, '*'), 2000);
    return () => clearTimeout(timer);
  }, [amount, ceviantTransactionId])

  return (
    <div className='space-y-4 text-[#535353] font-semibold text-2xl p-6 flex flex-col items-center text-center justify-center'>
      <h2>Payment Successful</h2>
      <p className='text-[#9E9E9E] text-xl'>You paid NGN {amount} to {merchantName}</p>
    </div>
  )
}

export default Success