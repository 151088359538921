import React from 'react';
import ReactDOM from 'react-dom/client';
import '@fontsource/ibm-plex-sans/400.css';
import '@fontsource/ibm-plex-sans/500.css';
import '@fontsource/ibm-plex-sans/600.css';
import '@fontsource/ibm-plex-sans/700.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ICheckoutProps } from './types';
import axiosInstance from './utils/axiosInstance';
import PageProvider from 'context/pageContext';
import CardProvider from 'context/cardContext';
import TransactionProvider from 'context/transactionContext';
import TransferProvider from 'context/TransferContext';
import ErrorProvider from 'context/ErrorContext';

window.onmessage = (event) => {
  if (!event?.data?.publicKey) {
    return;
  }
  axiosInstance.interceptors.request.use((config) => {
    config.headers['Authorization'] = `${event.data.publicKey}`;
    return config;
  });
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );
  root.render(
    <React.StrictMode>
      <PageProvider>
        <CardProvider>
          <TransferProvider>
            <TransactionProvider>
              <ErrorProvider>
                <App {...(event.data as ICheckoutProps)} />
              </ErrorProvider>
            </TransactionProvider>
          </TransferProvider>
        </CardProvider>
      </PageProvider>
    </React.StrictMode>
  );
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
