import React from 'react';

interface IconProps {
  svg: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  size?: number;
  className?: string;
}

const Icon: React.FC<IconProps> = ({ svg: Svg, size = 24, className = '' }) => {
  return <Svg width={size} height={size} className={className} />;
};

export default Icon;