import { usePageContext } from "context/hooks/usePageContext";
import { useEffect } from "react";
import Icon from "components/ui/Icon/Icon";
import Success from "./Success";

import { ReactComponent as Spinner } from "../../assets/icons/spinner.svg"

const Authenticating = ({ amount }: { amount: number }) => {
  const { changeSub, sub } = usePageContext();

  useEffect(() => {
    if (sub === "Authenticating") {
      const timer = setTimeout(() => changeSub("Processing"), 3000);
      return () => clearTimeout(timer);
    } else if (sub === "Processing") {
      const timer = setTimeout(() => changeSub("Success"), 4000);
      return () => clearTimeout(timer);
    }
  }, [changeSub, sub]);

  const RenderState = () => {
    if (sub === "Authenticating") {
      return (
        <>
          <img src="/icons/ceviant.svg" alt="Ceviant Logo" className='w-28' />
          <div className='space-y-6 text-center '>
            <h3 className='uppercase text-2xl font-semibold'>Ceviant Authentication</h3>
            <p className='font-semibold text-lg text-[#535353]'>You are being redirected to your bank to protect your card against unauthorized use.</p>
          </div>
        </>
      );
    }
    if (sub === "Processing") {
      return (
        <>
          <Icon svg={Spinner} size={40} />
          <div className='space-y-4 text-center text-[#535353]'>
            <h3 className='uppercase text-xl font-semibold'>Processing Transaction</h3>
          </div>
        </>
      );
    }
    if (sub === "Success") {
      return (
        <>
          <img src="/icons/ceviant.svg" alt="Ceviant Logo" className='w-28' />
          <Success amount={amount} />
        </>
      );
    }
    return null;
  };

  return (
    <main className='space-y-10 w-screen h-screen overflow-hidden flex flex-col items-center justify-center bg-white lg:bg-auth-bg bg-center bg-no-repeat bg-cover'>
      <RenderState />
    </main>
  );
};

export default Authenticating;
