import {
  ICheckoutProps,
  ITransactionInitiationPayload,
  ITransactionInitiationResponse,
} from './types';
import { formatCurrency } from 'utils';
import Icon from 'components/ui/Icon/Icon';
import { usePageContext } from 'context/hooks/usePageContext';
import PaymentSelector from 'components/ui/Button/PaymentSelector';
import PageSwitcher from 'components/PageSwitcher';
import Authenticating from 'components/screens/Authenticating';
import axiosInstance from 'utils/axiosInstance';
import { AxiosResponse } from 'axios';
import { PAYMENT_PROVIDER } from 'types/enums';
import { useTransactionContext } from 'context/hooks/useTransactionContext';
import { ReactComponent as Transfer } from './assets/icons/transfer.svg'
import { ReactComponent as CreditCard } from './assets/icons/creditcard.svg'
import { ReactComponent as Cancel } from './assets/icons/cancel.svg'
import { useTransferContext } from 'context/hooks/useTransferContext';

import { ReactComponent as Spinner } from './assets/icons/spinner.svg'

function App({
  customerEmail,
  customerPhone,
  cartItems,
  customerId,
  amount,
  currency,
}: ICheckoutProps) {
  const { changePage, changeSub, page } = usePageContext()
  const { changeTransactionDetails, ceviantTransactionId, merchantName } = useTransactionContext()
  const { changeTransferDetails, virtualAccountNumber } = useTransferContext();

  const handlePageChange = (name: string) => {
    changePage(name);
    changeSub(name);
  }

  const initiateTransaction = async () => {
    try {
      const response = await axiosInstance.post<
        ITransactionInitiationPayload,
        AxiosResponse<ITransactionInitiationResponse>
      >('/transactions', {
        provider: PAYMENT_PROVIDER.PAYSTACK,
        customerEmail,
        customerPhone,
        amount: amount * 100,
        currency,
        customerId,
        lineItems: cartItems.map((item) => ({
          metadata: item.metadata,
          quantity: item.quantity,
          totalPrice: item.totalPrice * 100,
          unitPrice: item.unitPrice * 100,
        })),
      });
      if (response.data) {
        changeTransactionDetails(response.data)
        changePage("Card")
      }
    } catch (error) {
      console.error(error);
    }
  };

  const initiateTransfer = async () => {
    changePage("Transfer")
    if (virtualAccountNumber === "") {
      changeSub("Loading")
      try {
        const response = await axiosInstance.post("/virtualAccounts", {
          transactionId: ceviantTransactionId,
          amount: amount * 100,
          email: customerEmail,
        })
        if (response.data) {
          changeTransferDetails(response.data)
          changeSub("Transfer")
        }
      } catch (error) {
        console.error(error)
        changeSub("Error")
      }
    } else {
      changeSub("Transfer")
    }
  }

  if (ceviantTransactionId === "") {
    initiateTransaction()
  }

  return (
    <>
      {
        page === "Authenticating" && (<Authenticating amount={amount} />)
      }
      {
        page !== "Authenticating" && (
          <div className="font-primary flex w-screen h-screen max-h-screen 
      lg:bg-Primary-200/80 bg-white justify-center overflow-hidden"
          >
            <div className='w-full lg:grid lg:place-items-center lg:p-10'>
              {/* Mobile */}
              <div className='bg-[#f4f4f4] h-screen lg:hidden w-full'>
                {
                  page === "" && (
                    <div>
                      <img src="/ceviant_logo.svg" alt="logo" className='p-4 pb-0' />
                      <h3 className='text-center py-6 text-xl'>You are about to pay{" "}
                        <span className='font-bold'>{formatCurrency(amount, 'NGN')}</span>{" "}
                        to {merchantName}
                      </h3>
                      <div className='border-b border-t'>
                        <div
                          className='flex items-center gap-2 text-[#4c4c4c] p-4 cursor-pointer border-b'
                          onClick={initiateTransfer}>
                          <Icon size={16} svg={Transfer} />
                          <p>Pay with Transfer</p>
                        </div>
                        <div
                          className='flex items-center gap-2 text-[#4c4c4c] p-4 cursor-pointer'
                          onClick={() => handlePageChange("Card")}>
                          <Icon size={16} svg={CreditCard} />
                          Pay with Card
                        </div>
                      </div>
                      <button
                        className='flex items-center gap-1 border p-2 m-auto mt-8 text-[#2E3C4E] bg-[#e4e4e4] text-sm 
                  font-semibold rounded-md'
                        onClick={() =>
                          window.parent.postMessage({ message: 'closeCeviantSDK' }, '*')
                        }
                      >
                        <Icon size={20} svg={Cancel} className='text-[#2E3C4E]' />
                        Cancel Payment
                      </button>
                    </div>
                  )
                }
                {
                  page !== "" && (<PageSwitcher amount={amount} email={customerEmail} />)
                }
              </div>

              {/* Desktop */}
              <div className='max-lg:hidden bg-[#f4f4f4] mx-auto w-full max-w-5xl max-h-[620px] rounded-2xl h-full flex'>
                <div className='bg-white w-1/3 p-5 pt-10 space-y-8 h-full rounded-es-2xl rounded-ss-2xl'>
                  <header>
                    <img src="/ceviant_logo.svg" alt="logo" className='w-[80%] m-auto' />
                  </header>
                  <div className='pl-4'>
                    <p className='uppercase text-[#121212] mb-4 font-semibold text-xl pl-2'>Pay With</p>
                    <div className='space-y-2'>
                      <PaymentSelector name='Transfer' icon={Transfer} onClick={initiateTransfer} />
                      <PaymentSelector name='Card' icon={CreditCard} handlePageChange={handlePageChange} />
                    </div>
                  </div>
                </div>
                <div className={`px-10 py-6 w-2/3 h-full max-h-[800px] space-y-6`}>
                  {
                    page === "" ? (
                      <div className='flex flex-col items-center justify-center h-full'>
                        <Icon svg={Spinner} size={70} />
                        <p>Loading</p>
                      </div>
                    ) : (
                      <section className={`flex flex-col items-stretch justify-center h-full gap-4`}>
                        <h3 className='text-center xl:pt-6 text-lg'>You are about to pay{" "}
                          <span className='font-bold'>{formatCurrency(amount, 'NGN')}</span>{" "}
                          to {merchantName}
                        </h3>
                        <PageSwitcher amount={amount} email={customerEmail} />
                        <div className='flex items-center gap-3 justify-center text-[#1A1C21] text-xl'>
                          Powered by <img src="/ceviant_logo.svg" alt="logo" />
                        </div>
                      </section>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        )
      }
    </>

  );
}

export default App;
