import { SwitcherProps } from 'components/PageSwitcher';
import { usePageContext } from 'context/hooks/usePageContext';
import { FC } from 'react';
import CardComponent from './cardScreens/CardComponent';
import PinAuth from './cardScreens/PinAuth';
import OtpAuth from './cardScreens/OtpAuth';
import PhoneAuth from './cardScreens/PhoneAuth';
import BirthdayAuth from './cardScreens/BirthdayAuth';
import AddressAuth from './cardScreens/AddressAuth';
import Timeout from 'components/screens/Timeout';
import ErrorComponent from 'components/screens/Error';

const PayWithCard: FC<SwitcherProps> = ({ amount, email }) => {
  const { sub } = usePageContext();

  return (
    <section style={{ fontFamily: "Inter, sans-serif" }} className='w-full md:max-w-sm m-auto space-y-4'>
      {sub === "Card" && (<CardComponent amount={amount} email={email} />)}
      {sub === "PinAuth" && (<PinAuth />)}
      {sub === "OtpAuth" && (<OtpAuth />)}
      {sub === "PhoneAuth" && (<PhoneAuth />)}
      {sub === "BirthdayAuth" && (<BirthdayAuth />)}
      {sub === "AddressAuth" && (<AddressAuth />)}
      {sub === "Timeout" && (<Timeout />)}
      {sub === "Error" && (<ErrorComponent />)}
    </section>
  );
};

export default PayWithCard;