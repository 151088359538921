import React, { FC, ButtonHTMLAttributes } from "react";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "primary" | "outline";
  className?: string;
}

const Button: FC<ButtonProps> = ({ variant = "primary", disabled, className, children, ...props }) => {
  const baseStyles =
    "px-4 py-2 rounded-md text-sm font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2";

  const primaryStyles = `
    bg-[#0284f8] text-white focus:ring-[#0284f8] 
    ${disabled ? "bg-opacity-60 cursor-not-allowed" : "hover:bg-opacity-90"}`;

  const outlineStyles = `
    bg-white text-[#0284f8] border border-[#0284f8] 
    ${disabled ? "opacity-60 cursor-not-allowed" : "hover:bg-[#0284f8] hover:text-white"}`;

  const variantStyles = variant === "primary" ? primaryStyles : outlineStyles;

  return (
    <button
      style={{
        fontFamily: "Urbanist, sans-serif"
      }}
      className={`${baseStyles} ${variantStyles} ${className} w-full !py-4 font-semibold text-lg`}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
