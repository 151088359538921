import { createContext, ReactNode, useCallback, useMemo, useState } from 'react';

type TransferContextProps = {
  transactionId: string;
  emailAddress: string | null;
  phone: string | null;
  amount: number;
  virtualAccountNumber: string;
  virtualAccountName: string;
  bankCode: string;
  currency: string;
  validityTime: number;
  changeTransferDetails: (details: Partial<TransferContextProps>) => void;
};

export const TransferContext = createContext<TransferContextProps>({
  transactionId: '',
  emailAddress: null,
  phone: null,
  amount: 0,
  virtualAccountNumber: '',
  virtualAccountName: '',
  bankCode: '',
  currency: '',
  validityTime: 0,
  changeTransferDetails: () => {},
});

const TransferProvider = ({ children }: { children: ReactNode }) => {
  const [transferDetails, setTransferDetails] = useState<TransferContextProps>({
    transactionId: '',
    emailAddress: null,
    phone: null,
    amount: 0,
    virtualAccountNumber: '',
    virtualAccountName: '',
    bankCode: '',
    currency: '',
    validityTime: 0,
    changeTransferDetails: () => {},
  });

  const changeTransferDetails = useCallback(
    (details: Partial<TransferContextProps>) => {
      setTransferDetails((prevDetails) => ({
        ...prevDetails,
        ...details,
      }));
    },
    [setTransferDetails]
  );

  const contextValue = useMemo(
    () => ({ ...transferDetails, changeTransferDetails }),
    [transferDetails, changeTransferDetails]
  );

  return (
    <TransferContext.Provider value={contextValue}>
      {children}
    </TransferContext.Provider>
  );
};

export default TransferProvider;
