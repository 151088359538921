import React, { useState } from 'react';
import Button from 'components/ui/Button/Button';
import FloatingLabelInput from 'components/ui/Inputs/FloatingLabelInput';
import { SwitcherProps } from 'components/PageSwitcher';
import { usePageContext } from 'context/hooks/usePageContext';
import { FC } from 'react';
import { checkResponse, formatCurrency } from 'utils';
import { validateCardForm } from 'utils/validation';
import axiosInstance from 'utils/axiosInstance';
import { useCardContext } from 'context/hooks/useCardContext';
import { useTransactionContext } from 'context/hooks/useTransactionContext';
import { debounce } from 'utils';
import { encryptCardDetails, importBase64PublicKey } from 'utils/encryption';
import useGetCardPaymentStatus from 'hooks/useGetCardPaymentStatus';

const CardComponent: FC<SwitcherProps> = ({ amount, email }) => {
  const { changeSub, changePage } = usePageContext();
  const { changeCardDetails } = useCardContext();
  const { ceviantTransactionId, merchantCollectionPublicKey } = useTransactionContext();
  const { triggerFetch, error } = useGetCardPaymentStatus(ceviantTransactionId);

  const [cardForm, setCardForm] = useState({
    cardNumber: "",
    expirationDate: "",
    cvv: "",
  });
  const [cardErrors, setCardErrors] = useState<Record<string, string>>({});
  const [isCardSubmitting, setIsCardSubmitting] = useState(false);

  const validateCardFormDebounced = debounce((form: typeof cardForm) => {
    const { errors } = validateCardForm(form);
    setCardErrors(errors);
  }, 500);

  const handleCardChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCardForm((prevForm) => {
      const updatedForm = { ...prevForm, [name]: value };
      validateCardFormDebounced(updatedForm);
      return updatedForm;
    });
  };

  const handleExpirationDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target;
    if (value.length === 2 && !value.includes('/')) {
      value = `${value}/`;
    }
    setCardForm((prevForm) => {
      const updatedForm = { ...prevForm, expirationDate: value };
      validateCardFormDebounced(updatedForm);
      return updatedForm;
    });
  };

  const handleCardSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const validation = validateCardForm(cardForm);
    if (validation.isValid) {
      changeCardDetails(cardForm);
      try {
        const expMonth = cardForm.expirationDate.split('/')[0];
        const expYear = cardForm.expirationDate.split('/')[1];

        const publicKey = importBase64PublicKey(merchantCollectionPublicKey);
        const encryptedCardDetails = encryptCardDetails(publicKey, {
          cvv: cardForm.cvv,
          number: cardForm.cardNumber,
          expiryMonth: expMonth,
          expiryYear: expYear,
        });

        const payload = {
          transactionId: ceviantTransactionId,
          email: email,
          amount: amount,
          card: encryptedCardDetails,
        };

        setIsCardSubmitting(true);
        const response = await axiosInstance.post('/payments', payload);

        if (response.data) {
          checkResponse(response.data.response, changeSub, changePage)
        }
      } catch (error) {
        triggerFetch();
      } finally {
        setIsCardSubmitting(false);
      }
    } else {
      setCardErrors(validation.errors);
      setIsCardSubmitting(false);
    }
  };

  if (error) {
    changeSub("Error")
  }

  return (
    <>
      <div className='text-center p-5 bg-[#C9E5FF] rounded-sm w-full max-w-xs m-auto max-lg:hidden'>
        <p>You are about to pay:</p>
        <span className='font-bold text-2xl text-[#0284F8] pt-1'>{formatCurrency(amount, 'NGN')}</span>
      </div>
      <h4 className='text-center'>Enter your card details to pay</h4>
      <form onSubmit={handleCardSubmit} className='space-y-3 px-4 md:px-0'>
        <div>
          <FloatingLabelInput
            name="cardNumber"
            type="number"
            label="CARD NUMBER"
            value={cardForm.cardNumber}
            onChange={handleCardChange}
          />
          {cardErrors.cardNumber && (
            <p className="text-red-500 text-sm">{cardErrors.cardNumber}</p>
          )}
        </div>
        <div className='grid grid-cols-2 gap-3'>
          <div>
            <FloatingLabelInput
              label="MM/YY"
              type="text"
              value={cardForm.expirationDate}
              onChange={handleExpirationDateChange}
              name="expirationDate"
            />
            {cardErrors.expirationDate && (
              <p className="text-red-500 text-sm mt-1">{cardErrors.expirationDate}</p>
            )}
          </div>
          <div>
            <FloatingLabelInput
              label="CVV"
              type="number"
              value={cardForm.cvv}
              onChange={handleCardChange}
              name="cvv"
            />
            {cardErrors.cvv && (
              <p className="text-red-500 text-sm mt-1">{cardErrors.cvv}</p>
            )}
          </div>
        </div>
        <div className='w-full flex gap-4'>
          <Button type="submit" variant='primary' disabled={isCardSubmitting || Object.keys(cardErrors).length > 0}>
            Pay {formatCurrency(amount, 'NGN')}
          </Button>
          <Button variant='outline' onClick={() =>
            window.parent.postMessage({ message: 'closeCeviantSDK' }, '*')}>Cancel</Button>
        </div>
      </form>
    </>
  );
};

export default CardComponent;