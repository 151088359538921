import forge from "node-forge";

interface CardDetails {
  number: string;
  expiryMonth: string;
  expiryYear: string;
  cvv: string;
}

// Convert base64 Public key to a Forge public key
export const importBase64PublicKey = (base64Key: string): string => {
  const pemKey = `-----BEGIN PUBLIC KEY-----\n${base64Key}\n-----END PUBLIC KEY-----`;
  return pemKey;
};

// Encrypt card details using the Forge RSA public key with PKCS#1 padding
export const encryptCardDetails = (publicKey: string, object: CardDetails): string => {
  const cardString = JSON.stringify(object);
  const forgePublicKey = forge.pki.publicKeyFromPem(publicKey) as forge.pki.rsa.PublicKey;

  // Encrypt the JSON string using PKCS#1
  const encrypted = forgePublicKey.encrypt(cardString, "RSAES-PKCS1-V1_5");

  // Convert the encrypted data to Base64
  return forge.util.encode64(encrypted);
};

